import React from "react";

import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function Loading() {
  return (
    <Row>
      <Col className="text-primary text-center">
        <FontAwesomeIcon className="fa-spin" size="2x" icon={faSpinner} />
        <p>Carregando</p>
      </Col>
    </Row>
  );
}
