import api from "../../services/api";
import * as types from "./actionTypes";

export const fiscalInformationStart = () => {
  return {
    type: types.FISCAL_INFORMATION_START,
  };
};

export const fiscalInformationEnd = () => {
  return {
    type: types.FISCAL_INFORMATION_END,
  };
};

export const fetchFiscalInformation = (information) => {
  return {
    type: types.FETCH_FISCAL_INFORMATION,
    payload: information,
  };
};

export const getBusinessFiscalInformation = (business_id) => {
  return (dispatch) => {
    dispatch(fiscalInformationStart());
    return api.get(`businesses/${business_id}/fiscal_informations`).then((response) => {
      dispatch(fetchFiscalInformation(response.data.fiscal_informations[0]));
      dispatch(fiscalInformationEnd());
    });
  };
};

export const updateFiscalInformation = (data) => {
  return new Promise((resolve, reject) => {
    api
      .put("fiscal_informations", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((response) => {
        reject(response);
      });
  });
};

const fetchOptionsFiscalInformation = (options) => {
  return {
    type: types.FETCH_OPTIONS_FISCAL_INFORMATION,
    payload: options,
  };
};

export const getFiscalOptions = () => {
  return (dispatch) => {
    return api.get("taxations").then((response) => {
      dispatch(fetchOptionsFiscalInformation(response.data));
    });
  };
};

export const handleChangeFiscal = (formData) => {
  return (dispatch) => {
    return api
      .put(`businesses/${formData.business_id}/fiscal_informations/${formData.id}`, { fiscal_information: formData })
      .then(({ data }) => {
        dispatch(fetchFiscalInformation(data.fiscal_information));
      });
  };
};