import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import axios from 'axios';

const INITIAL_ADDRESS = {
  cep: '',
  logradouro: '',
  tipo_logradouro: '',
  complemento: '',
  bairro: '',
  numero: '',
  uf: '',
  codigo_ibge: '',
  cidade: '',
}

const useViacep = (value, numberRef = null) => {
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState(INITIAL_ADDRESS);
  const [error, setError] = useState({ hasError: false, message: "" });
  const prevAddressRef = useRef();

  // Get the previous address state value
  useEffect(() => {
    prevAddressRef.current = address;
  });

  useEffect(() => {
    if (!address.cep && value.cep) {
      const newValue = value;
      delete newValue.cnpj;
      delete newValue.razao_social;
      delete newValue.inscricao_municipal;
      delete newValue.certificate;
      delete newValue.certificate_password;
      setAddress(value);
      prevAddressRef.current = value;
    }
  }, [value, address]);

  const prevAddress = prevAddressRef.current;

  const cleanCep = useMemo(() => String(address.cep).replace(/\D+/g, ""), [address.cep]);
  const viaCepUrl = useMemo(() => {
    return `https://viacep.com.br/ws/${cleanCep}/json/`;
  }, [cleanCep]);

  const searchCep = useCallback(async () => {
    // Prevent create a loop
    if (prevAddress.cep === address.cep) return;

    setLoading(true);
    setError({ hasError: false, message: "" });
    setAddress({
      ...address,
      logradouro: '...',
      tipo_logradouro: '',
      complemento: '...',
      bairro: '...',
      uf: '',
      numero: '',
      codigo_ibge: '',
      cidade: '',
    });

    axios.get(viaCepUrl)
      .then(({ data }) => {
        setLoading(false);
        if (data.erro) {
          setError({ hasError: true });
          return;
        }
        setAddress({
          ...address,
          logradouro: data.logradouro,
          complemento: data.complemento,
          bairro: data.bairro,
          uf: data.uf,
          codigo_ibge: data.ibge,
          cidade: data.localidade,
          tipo_logradouro: data.logradouro.substr(0, data.logradouro.indexOf(' ')),
        });
        // If numberRef is set, change the focus to it
        if (numberRef && numberRef.current) {
          numberRef.current.focus();
        }
      })
      .catch(() => {
        setError({ hasError: true });
      });
  }, [numberRef, viaCepUrl, address, prevAddress]);

  useEffect(() => {
    if (cleanCep.length === 8) {
      searchCep();
    }
  }, [cleanCep, searchCep]);

  const handleChange = (e) => {
    const { name, value, options, selectedIndex } = e.target;
    if (name === 'codigo_ibge') {
      setAddress({
        ...address,
        cidade: options[selectedIndex].text
      });
    }

    setAddress({
      ...address,
      [name]: value
    });
  }

  return [loading, address, handleChange, error];
};

export default useViacep;