import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchAllStates,
  fetchCitiesByState,
} from "../../store/actions/locationAction";
import { getAllUserBusiness, handleChangeBusiness } from "../../store/actions/businessAction";

import { Container, Row, Col, Card, Form } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import useViacep from "../../hooks/useViacep";
import Swal from "sweetalert2";

export default function General() {
  const selectedBusinesses = useSelector(
    (state) => state.business && state.business.selected_business
  );

  const [fileName, setFileName] = useState('Selecione o seu certificado digital');
  const [formContent, setFormContent] = useState({ ...selectedBusinesses });
  const [ownerContent, setOwnerContent] = useState({
    ...selectedBusinesses.business_owner,
  });
  const [disabled, setDisabled] = useState(false);

  const dispatch = useDispatch();
  const { states, cities } = useSelector((state) => state.location);

  const numberRef = useRef(null);
  const [loading, address, setAddress, error] = useViacep(
    { ...formContent },
    numberRef
  );

  useEffect(() => {
    dispatch(fetchAllStates());
  }, [dispatch]);

  useEffect(() => {
    setFileName(selectedBusinesses.certificate.substring(selectedBusinesses.certificate.lastIndexOf("/") + 1));
  }, [selectedBusinesses])

  useEffect(() => {
    if (address.uf) dispatch(fetchCitiesByState(address.uf));
  }, [dispatch, address.uf]);

  function handleSubmit(e) {
    e.preventDefault();
    setDisabled(true);
    const formData = new FormData();

    const data = { ...formContent, ...address };

    for (const [key, value] of Object.entries(data)) {
      formData.append(`business[${key}]`, value);
    }

    for (const [key, value] of Object.entries(ownerContent)) {
      formData.append(`business[business_owner_attributes][${key}]`, value);
    }

    if (typeof formContent.certificate === "object") {
      formData.set("business[certificate]", formContent.certificate);
    } else {
      formData.delete("business[certificate]");
    }

    dispatch(handleChangeBusiness(formData))
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Informações gerais atualizadas com sucesso",
        });
        dispatch(getAllUserBusiness());
      })
      .catch((response) => {
        const fields = response.data.errors.fields.replaceAll(",", "<br/>");
        Swal.fire({
          icon: "error",
          title: "Não foi possível atualizar as informações gerais",
          html: fields,
        });
      })
      .finally(() => {
        setDisabled(false);
      });
  }

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'certificate') {
      setFileName(files[0].name);
    }
    setFormContent({
      ...formContent,
      [name]: files ? files[0] : value,
    });
  };

  const handleChangeOwner = (e) => {
    const { name, value } = e.target;
    setOwnerContent({
      ...ownerContent,
      [name]: value,
    });
  };

  return (
    <Container>
      <Row>
        <Col>
          <h6 className="mb-0">Informações gerais</h6>
          <h1 className="text-primary">
            {selectedBusinesses && selectedBusinesses.razao_social}
          </h1>
        </Col>
      </Row>
      <Row>
        <Col className="mt-4">
          <Card className="border-0 shadow-sm">
            <Card.Body>
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <h6 className="mb-0">Informações do proprietário</h6>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-2" lg={4}>
                    <Form.Group className="required">
                      <Form.Label>Nome completo</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        className="form-control"
                        id="owner-name"
                        name="nome_completo"
                        value={ownerContent.nome_completo}
                        onChange={handleChangeOwner}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="mt-2" lg={4}>
                    <Form.Group className="required">
                      <Form.Label>CPF</Form.Label>
                      <MaskedInput
                        required
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          ".",
                          /\d/,
                          /\d/,
                          /\d/,
                          ".",
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                        ]}
                        name="cpf"
                        guide={false}
                        value={ownerContent.cpf}
                        onChange={handleChangeOwner}
                        render={(ref, props) => (
                          <Form.Control ref={ref} {...props} />
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="mt-2" lg={4}>
                    <Form.Group>
                      <Form.Label>Data de nascimento</Form.Label>
                      <Form.Control
                        type="date"
                        className="form-control"
                        id="owner-birthdate"
                        name="data_nascimento"
                        value={ownerContent.data_nascimento}
                        onChange={handleChangeOwner}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <h6 className="mb-0">Informações do estabelecimento</h6>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-2" lg={4}>
                    <Form.Group className="required">
                      <Form.Label>CNPJ</Form.Label>
                      <MaskedInput
                        mask={[
                          /\d/,
                          /\d/,
                          ".",
                          /\d/,
                          /\d/,
                          /\d/,
                          ".",
                          /\d/,
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                        ]}
                        name="cnpj"
                        guide={false}
                        value={formContent.cnpj}
                        onChange={handleChange}
                        required
                        render={(ref, props) => (
                          <Form.Control ref={ref} {...props} />
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="mt-2" lg={4}>
                    <Form.Group className="required">
                      <Form.Label>Inscrição Municipal</Form.Label>
                      <Form.Control
                        type="number"
                        className="form-control hide-number-arrows"
                        pattern="[0-9]*"
                        id="inscricao_municipal"
                        name="inscricao_municipal"
                        value={formContent.inscricao_municipal}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="mt-2" lg={4}>
                    <Form.Group className="required">
                      <Form.Label>Razão social</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        className="form-control"
                        id="razao_social"
                        name="razao_social"
                        value={formContent.razao_social}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-2" lg={6} xl={3}>
                    <Form.Group className="required">
                      <Form.Label>CEP</Form.Label>
                      <MaskedInput
                        isInvalid={error.hasError}
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        required
                        value={address.cep}
                        name="cep"
                        guide={false}
                        onChange={setAddress}
                        render={(ref, props) => (
                          <Form.Control ref={ref} {...props} />
                        )}
                      />
                      {error.hasError && (
                        <Form.Text className="text-danger">
                          CEP não encontrado
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-2" lg={6} xl={4}>
                    <Form.Group className="required">
                      <Form.Label>Rua</Form.Label>
                      <Form.Control
                        value={address.logradouro}
                        required
                        name="logradouro"
                        onChange={setAddress}
                        type="text"
                      />
                    </Form.Group>
                  </Col>
                  <Col className="mt-2" lg={6} xl={4}>
                    <Row>
                      <Col lg={6}>
                        <Form.Group className="required">
                          <Form.Label>Número</Form.Label>
                          <Form.Control
                            value={address.numero}
                            required
                            ref={numberRef}
                            name="numero"
                            onChange={setAddress}
                            type="text"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group>
                          <Form.Label>Complemento</Form.Label>
                          <Form.Control
                            value={address.complemento}
                            name="complemento"
                            onChange={setAddress}
                            type="text"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mt-2" lg={6} xl={4}>
                    <Form.Group className="required">
                      <Form.Label>Bairro</Form.Label>
                      <Form.Control
                        value={address.bairro}
                        required
                        name="bairro"
                        onChange={setAddress}
                        type="text"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col lg={4}>
                    <Form.Group className="required">
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        value={address.codigo_ibge}
                        required
                        name="codigo_ibge"
                        onChange={setAddress}
                        as="select"
                      >
                        <option value="" disabled>
                          {cities.length > 0
                            ? "Selecione a cidade"
                            : address.uf
                            ? "..."
                            : "Selecione um estado"}
                        </option>
                        {cities &&
                          cities.map((city) => (
                            <option key={city.id} value={city.id}>
                              {city.nome}
                            </option>
                          ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="required">
                      <Form.Label>Estado</Form.Label>
                      <Form.Control
                        required
                        value={address.uf}
                        name="uf"
                        onChange={setAddress}
                        as="select"
                      >
                        <option value="" disabled>
                          {states.length > 0 ? "Selecione o estado" : "..."}
                        </option>
                        {states &&
                          states.map((state) => (
                            <option value={state.sigla} key={state.id}>
                              {state.nome}
                            </option>
                          ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="required">
                      <Form.Label>Certificado Digital</Form.Label>
                      <Form.File
                        accept=".pfx, .p12"
                        required={formContent.certificate === ""}
                        name="certificate"
                        onChange={handleChange}
                        label={fileName}
                        data-browse="Selecionar"
                        custom
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="required">
                      <Form.Label>Senha do Certificado Digital</Form.Label>
                      <Form.Control
                        required
                        type="password"
                        className="form-control"
                        name="certificate_password"
                        placeholder="Senha do certificado"
                        value={formContent.certificate_password}
                        onChange={handleChange}
                        accept=".pfx, .p12"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-right mt-4">
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary px-4"
                      disabled={disabled || loading}
                    >
                      Salvar
                    </button>
                  </Col>
                </Row>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
