import { combineReducers } from 'redux';

import invoicesReducer from './invoicesReducer';
import locationReducer from './locationReducer';
import fiscalInformationReducer from './fiscalInformationReducer';
import bussinessReducer from './businessReducer';

export default combineReducers({
  invoices: invoicesReducer,
  location: locationReducer,
  fiscalInformation: fiscalInformationReducer, 
  business: bussinessReducer,
});