import * as types from '../actions/actionTypes';
const initialState = {
  loading: false,
  states: [],
  cities: [],
};

export default function locationReducer(state = initialState, action) {
  switch(action.type) {
    case types.LOCATION_START:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_STATES:
      return {
        ...state,
        states: action.payload,
      };

    case types.FETCH_CITIES:
      return {
        ...state,
        cities: action.payload,
      }
    
    case types.LOCATION_END:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}