import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import reducers from './reducers'; 

const persistConfig = {
  key: 'root',
  storage,
}

const presistedReducer = persistReducer(persistConfig, reducers );
const store = createStore(presistedReducer, applyMiddleware(thunk));
const persistor = persistStore(store);

export { persistor, store };