import React from "react";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Card, Table } from "react-bootstrap";

export default function Invoices() {
  return (
    <Container>
      <Row>
        <Col>
          <h6 className="mb-0">Notas emitidas</h6>
          <h1 className="text-primary">01/11/20 até 30/11/20</h1>
        </Col>
        <Col lg={6} className="text-center text-lg-right mt-2 my-lg-auto">
          <button
            type="button"
            className="btn btn-outline-primary btn-sm raleway"
          >
            <FontAwesomeIcon icon={faCalendarAlt} className="mr-1" />
            Trocar período
          </button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg="12">
          <Card>
            <Card.Body className="py-2">
              <p className="text-dark raleway">Últimas notas emitidas</p>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Data</th>
                    <th>Descrição</th>
                    <th>Valor</th>
                    <th>Cliente</th>
                    <th>CPF</th>
                    <th>E-mail</th>
                    <th>Telefone</th>
                    <th>Opções</th>
                  </tr>
                </thead>
                <tbody className="text-nowrap">
                  <tr>
                    <td>
                      <span className="status status-yellow"></span>
                    </td>
                    <td>03/11/2020</td>
                    <td>Corte de cabelo masculino</td>
                    <td>R$ 35,00</td>
                    <td>José Carlos da Silva</td>
                    <td>123.456.789-00</td>
                    <td>jose.silva@gmail.com</td>
                    <td>(12) 34567-8912</td>
                    <td className="text-primary">
                      <FontAwesomeIcon icon={faFilePdf} /> xml
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="status status-green"></span>
                    </td>
                    <td>03/11/2020</td>
                    <td>Corte de cabelo e barba masculino</td>
                    <td>R$ 35,00</td>
                    <td>José Carlos da Silva</td>
                    <td>123.456.789-00</td>
                    <td>jose.silva@gmail.com</td>
                    <td>(12) 34567-8912</td>
                    <td className="text-primary">
                      <Link to="/servicos/1">Emitir NFS</Link>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
