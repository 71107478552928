import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getBusinessFiscalInformation,
  getFiscalOptions,
  handleChangeFiscal,
} from "../../store/actions/fiscalInformationAction";

import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Loading from "../../components/Loading";
import Swal from "sweetalert2";

export default function Fiscal() {
  const dispatch = useDispatch();
  const selectedBusiness = useSelector(
    (state) => state.business && state.business.selected_business
  );

  const { fiscalInformation, options, loading } = useSelector(
    (state) => state.fiscalInformation
  );

  const [formContent, setFormContent] = useState({});

  function handleChange(e) {
    let { name, value } = e.target;

    // Parse value to Boolean when is true or false
    if (value === "true" || value === "false") {
      value = JSON.parse(value);
    }

    setFormContent({
      ...formContent,
      [name]: value,
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(handleChangeFiscal(formContent)).then(() => {
      Swal.fire({
        icon: "success",
        title: "Informações fiscais atualizadas com sucesso",
      });
    });
  }

  useEffect(() => {
    dispatch(getFiscalOptions());
    dispatch(getBusinessFiscalInformation()).then(() => {});
  }, [dispatch]);

  useEffect(() => {
    setFormContent({ ...fiscalInformation });
  }, [fiscalInformation]);

  return (
    <Container>
      <Row>
        <Col>
          <h6 className="mb-0">Informações fiscais</h6>
          <h1 className="text-primary">
            {selectedBusiness && selectedBusiness.razao_social}
          </h1>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg="12">
          <Card>
            <Card.Body className="py-2">
              {loading ? (
                <Loading />
              ) : (
                <>
                  <p className="text-dark raleway">
                    Informações fiscais e contabéis
                  </p>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col sm="12" lg="6" xl="3">
                        <Form.Group className="required">
                          <Form.Label>Natureza da operação</Form.Label>
                          <Form.Control
                            name="natureza_operacao"
                            value={formContent.natureza_operacao}
                            onChange={handleChange}
                            as="select"
                          >
                            <option value="" disabled>
                              Selecione uma opção
                            </option>
                            {options.natureza_operacao &&
                              options.natureza_operacao.map((option) => (
                                <option value={option.value} key={option.value}>
                                  {option.text}
                                </option>
                              ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12" lg="6" xl="3">
                        <Form.Group className="required">
                          <Form.Label>Regime especial de tributação</Form.Label>
                          <Form.Control
                            name="regime_especial_tributacao"
                            value={formContent.regime_especial_tributacao}
                            onChange={handleChange}
                            as="select"
                          >
                            <option value="" disabled>
                              Selecione uma opção
                            </option>
                            {options.regime_especial_tributacao &&
                              options.regime_especial_tributacao.map(
                                (option) => (
                                  <option
                                    value={option.value}
                                    key={option.value}
                                  >
                                    {option.text}
                                  </option>
                                )
                              )}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12" lg="6" xl="3">
                        <Form.Group className="required">
                          <Form.Label>Optante do simples nacional</Form.Label>
                          <Form.Control
                            name="optante_simples_nacional"
                            value={formContent.optante_simples_nacional}
                            onChange={handleChange}
                            as="select"
                          >
                            <option value="" disabled>
                              Selecione uma opção
                            </option>
                            <option value={true}>Sim</option>
                            <option value={false}>Não</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12" lg="6" xl="3">
                        <Form.Group className="required">
                          <Form.Label>Tributação RPS</Form.Label>
                          <Form.Control
                            name="taxation_id"
                            value={formContent.taxation_id}
                            onChange={handleChange}
                            as="select"
                          >
                            <option value="" disabled>
                              Selecione uma opção
                            </option>
                            {options.taxations &&
                              options.taxations.map((option) => (
                                <option value={option.id} key={option.id}>
                                  {option.descricao}
                                </option>
                              ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-right mt-4">
                        <Button
                          type="submit"
                          variant="primary"
                          size="sm"
                          className="px-4"
                        >
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
