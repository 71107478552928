// Invoices actions
export const INVOICES_START = "INVOICES_START";
export const FETCH_INVOICES = "FETCH_INVOICES";
export const INVOICES_END = "INVOICES_END";

// Location actions
export const LOCATION_START = "LOCATION_START";
export const FETCH_STATES = "FETCH_STATES";
export const FETCH_CITIES = "FETCH_CITIES";
export const LOCATION_END = "LOCATION_END";

// Fiscal Information Reducer
export const FISCAL_INFORMATION_START = "FISCAL_INFORMATION_START";
export const FISCAL_INFORMATION_END = "FISCAL_INFORMATION_END";
export const FETCH_FISCAL_INFORMATION = "FETCH_FISCAL_INFORMATION";
export const FETCH_OPTIONS_FISCAL_INFORMATION = "FETCH_OPTIONS_FISCAL_INFORMATION";

// Business Reducer

export const BUSINESS_START = "BUSINESS_START";
export const BUSINESS_END = "BUSINESS_END";
export const FETCH_BUSINESS = "FETCH_BUSINESS";
export const SELECT_BUSINESS = "SELECTs_BUSINESS";