import React, { useState, useRef, useEffect } from "react";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";

import MomentLocaleUtils from "react-day-picker/moment";

import "moment/locale/pt-br";

export default function DateRange() {
  const pickerRef = useRef(null);

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [enteredTo, setEnteredTo] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    }
  }, [open])

  const handleOpen = () => {
    setOpen(!open);
  }

  const handleClickOutside = (e) => {
    console.log(pickerRef.current, e.target);
    if (pickerRef.current && !pickerRef.current.wrapper.contains(e.target)) {
      setOpen(false);
    }
  }

  const handleResetClick = () => {
    setDateFrom(null);
    setDateTo(null);
    setEnteredTo(null);
  }

  const isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  const handleDayClick = (day) => {
    if (dateFrom && dateTo && day >= dateFrom && day <= dateTo) {
      handleResetClick();
      return;
    }
    if (isSelectingFirstDay(dateFrom, dateTo, day)) {
      setDateFrom(day);
      setDateTo(null);
      setEnteredTo(null);
    } else {
      setDateTo(day);
      setEnteredTo(day);
      setOpen(false);
    }
  }

  const handleDayMouseEnter = (day) => {
    if (!isSelectingFirstDay(dateFrom, dateTo, day)) {
      setEnteredTo(day)
    }
  };

  const modifiers = { start: dateFrom, end: enteredTo };
  const selectedDays = [dateFrom, { from: dateFrom, to: enteredTo }];
  const disabledDays = { before: dateFrom };

  return (
    <div className="position-relative d-inline-block">
      <button type="button" className="btn btn-outline-primary btn-sm raleway" onClick={handleOpen}>
        <FontAwesomeIcon icon={faCalendarAlt} className="mr-1" />
        Trocar período
      </button>
      <DayPicker
        ref={pickerRef}
        localeUtils={MomentLocaleUtils}
        locale="pt-br"
        className={`day_picker Range ${open ? 'opened' : ''}`}
        modifiers={modifiers}
        selectedDays={selectedDays}
        disabledDays={disabledDays}
        onDayClick={handleDayClick}
        onDayMouseEnter={handleDayMouseEnter}
      />
    </div>
  );
}
