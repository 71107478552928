import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchAllStates,
  fetchCitiesByState,
} from "../../store/actions/locationAction";

import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import useViacep from "../../hooks/useViacep";
import { handleSentInvoice } from "../../store/actions/invoicesAction";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const initialForm = {
  cpf: "",
  email: "",
  telefone: "",
  enviar: "",
};

export default function ServiceIssuance() {
  const dispatch = useDispatch();
  const { states, cities } = useSelector((state) => state.location);
  const history = useHistory();

  const numberRef = useRef(null);
  const [loading, address, setAddress, error] = useViacep(null, numberRef);
  const [formContent, setFormContent] = useState({ ...initialForm });
  const selectedBusinesses = useSelector(
    (state) => state.business && state.business.selected_business
  );

  const phoneMask = (v) => {
    const x = v.replace(/[^\d]+/g, "");
    return x.length < 11
      ? [
          "(",
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]
      : [
          "(",
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ];
  };

  function handleChange(e) {
    const { type, name, value, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    setFormContent({
      ...formContent,
      [name]: newValue,
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    const data = {
      discriminacao: "teste",
      valor_servicos: "29.97",
      data_servico: "2020-12-21",
      iss_retido: 1,
      item_lista_servico: "0001",
      status_id: 1,
      final_customer_attributes: {
        ...formContent,
        ...address,
      },
    };

    dispatch(handleSentInvoice(data)).then(() => {
      Swal.fire({
        icon: "success",
        title: "Nota fiscal cadastrada com sucesso",
        willClose: () => {
          history.push("/notas");
        },
      });
    });
  }

  useEffect(() => {
    dispatch(fetchAllStates());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchCitiesByState(address.uf));
  }, [dispatch, address.uf]);

  return (
    <Container>
      <Row>
        <Col>
          <h6 className="mb-0">Informações gerais</h6>
          <h1 className="text-primary">
            {selectedBusinesses && selectedBusinesses.razao_social}
          </h1>
        </Col>
      </Row>
      <Row>
        <Col className="mt-4">
          <Card className="border-0 shadow-sm">
            <Card.Body className="py-2">
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <p className="mb-0 raleway">Informações do serviço</p>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-start">
                  <div className="mt-1 mx-gap">
                    <p className="m-0 text-dark">Descrição</p>
                    <p className="m-0 text-dark h5 text-weight-300">
                      Corte de cabelo masculino
                    </p>
                  </div>
                  <div className="mt-1 mx-gap">
                    <p className="m-0 text-dark">Valor</p>
                    <p className="m-0 text-dark h5 text-weight-300">R$ 35,00</p>
                  </div>
                </Row>
                <Row className="mt-5">
                  <Col>
                    <h6 className="mb-1">Informações do cliente</h6>
                  </Col>
                  <div className="w-100"></div>
                  <Col className="mt-2" lg={6} xl={3}>
                    <Form.Group>
                      <Form.Label>CPF</Form.Label>
                      <MaskedInput
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          ".",
                          /\d/,
                          /\d/,
                          /\d/,
                          ".",
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                        ]}
                        name="cpf"
                        guide={false}
                        checked={formContent.cpf}
                        onChange={handleChange}
                        render={(ref, props) => (
                          <Form.Control ref={ref} {...props} />
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="mt-2" lg={6} xl={3}>
                    <Form.Group>
                      <Form.Label>Telefone</Form.Label>
                      <MaskedInput
                        mask={phoneMask}
                        name="telefone"
                        guide={false}
                        value={formContent.telefone}
                        onChange={handleChange}
                        render={(ref, props) => (
                          <Form.Control ref={ref} {...props} />
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="mt-2" lg={6} xl={3}>
                    <Form.Group>
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={formContent.email}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="mt-2 d-flex align-items-center" lg={6} xl={3}>
                    <Form.Group className="m-0 mt-2">
                      <Form.Check
                        type="checkbox"
                        name="enviar"
                        label="Enviar nota por e-mail"
                        value={formContent.enviar}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-2" lg={6} xl={3}>
                    <Form.Group>
                      <Form.Label>CEP</Form.Label>
                      <MaskedInput
                        isInvalid={error.hasError}
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        value={address.cep}
                        name="cep"
                        guide={false}
                        onChange={setAddress}
                        render={(ref, props) => (
                          <Form.Control ref={ref} {...props} />
                        )}
                      />
                      {error.hasError && (
                        <Form.Text className="text-danger">
                          CEP não encontrado
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-2" lg={6} xl={4}>
                    <Form.Group>
                      <Form.Label>Rua</Form.Label>
                      <Form.Control
                        value={address.logradouro}
                        name="logradouro"
                        onChange={setAddress}
                        type="text"
                      />
                    </Form.Group>
                  </Col>
                  <Col className="mt-2" lg={6} xl={4}>
                    <Row>
                      <Col lg={6}>
                        <Form.Group>
                          <Form.Label>Número</Form.Label>
                          <Form.Control
                            ref={numberRef}
                            name="numero"
                            onChange={setAddress}
                            type="text"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group>
                          <Form.Label>Complemento</Form.Label>
                          <Form.Control
                            value={address.complemento}
                            name="complemento"
                            onChange={setAddress}
                            type="text"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mt-2" lg={6} xl={4}>
                    <Form.Group>
                      <Form.Label>Bairro</Form.Label>
                      <Form.Control
                        value={address.bairro}
                        name="bairro"
                        onChange={setAddress}
                        type="text"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        value={address.codigo_ibge}
                        name="ibge"
                        onChange={setAddress}
                        as="select"
                      >
                        <option value="" disabled>
                          {cities.length > 0
                            ? "Selecione a cidade"
                            : address.uf
                            ? "..."
                            : "Selecione um estado"}
                        </option>
                        {cities &&
                          cities.map((city) => (
                            <option key={city.id} value={city.id}>
                              {city.nome}
                            </option>
                          ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label>Estado</Form.Label>
                      <Form.Control
                        value={address.uf}
                        name="uf"
                        onChange={setAddress}
                        as="select"
                      >
                        <option value="" disabled>
                          {states.length > 0 ? "Selecione o estado" : "..."}
                        </option>
                        {states &&
                          states.map((state) => (
                            <option value={state.sigla} key={state.id}>
                              {state.nome}
                            </option>
                          ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center mt-4">
                    <Button
                      variant="primary"
                      size="sm"
                      type="submit"
                      className="px-4"
                      disabled={loading}
                    >
                      Emitir nota fiscal de serviço
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
