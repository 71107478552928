import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getAllUserBusiness, selectBusiness } from '../../../store/actions/businessAction';

import { NavLink, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faCalculator,
  // faBookOpen,
  // faFileInvoice,
  faSignOutAlt,
  faTimes,
  faHome
} from "@fortawesome/free-solid-svg-icons";

import placeholder from "../../../assets/img/placeholder.png";
import { useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { handleLogout } from "../../../services/auth";

export default function Sidebar(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const businesses = useSelector(state =>
    state.business && state.business.businesses
  );
  const selectedBusinesses = useSelector(state =>
    state.business && state.business.selected_business
  );

  const usePageViews = () => {
    const location = useLocation();

    useEffect(() => {
      props.navbarChangeState();
    }, [location]);
  }

  useEffect(() => {
    dispatch(getAllUserBusiness());
  }, [dispatch]);

  const handleSelectBusiness = (business) => {
    dispatch(selectBusiness(business));
  }

  const handleLogoutAction = (e) => {
    e.preventDefault();

    handleLogout();
    history.push("/");
  }

  usePageViews(props);
  return (
    <nav
      id="main-nav"
      className={"bg-primary " + (props.navbarState ? "active" : "")}
    >
      <div className="text-right menu-button p-2">
        <button
          type="button"
          className="btn btn-sm btn-outline-light"
          onClick={() => props.navbarChangeState()}
        >
          <FontAwesomeIcon icon={faTimes} className="mr-2" />
          Fechar
        </button>
      </div>
      <div className="text-center py-5">
        <img
          src={placeholder}
          className="img-fluid rounded-circle avatar-img mb-2"
          alt="Perfil do usuário"
        />
        <p className="mb-1 text-light">{selectedBusinesses && selectedBusinesses.razao_social}</p>
        <Dropdown className="mb-4" drop="right">
          <Dropdown.Toggle variant="secondary" size="sm">
            Mudar estabelecimento
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {businesses.length > 0 && businesses.map((business) => (
              <Dropdown.Item onClick={() => {handleSelectBusiness(business)}} key={business.id} as="button">{business.razao_social}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <div>
          <button onClick={handleLogoutAction} className="btn text-light btn-sm">
            <FontAwesomeIcon icon={faSignOutAlt} className="mr-1" />
            Logout
          </button>
        </div>
      </div>
      <ul className="nav flex-column mt-2">
        <li className="nav-item">
          <NavLink className="nav-link" activeClassName="active" exact to="/">
            <FontAwesomeIcon icon={faHome} className="mr-2" />
            Dashboard
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-link"
            activeClassName="active"
            to="/cadastro-geral"
          >
            <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
            Informações gerais
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" activeClassName="active" to="/fiscal">
            <FontAwesomeIcon icon={faCalculator} className="mr-2" />
            Informações fiscais
          </NavLink>
        </li>
        {/* <li className="nav-item">
          <NavLink className="nav-link" activeClassName="active" to="/servicos">
            <FontAwesomeIcon icon={faBookOpen} className="mr-2" />
            Serviços realizados
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" activeClassName="active" to="/notas">
            <FontAwesomeIcon icon={faFileInvoice} className="mr-2" />
            Notas emitidas
          </NavLink>
        </li> */}
      </ul>
    </nav>
  );
}
