import React, { useState } from "react";
import { Redirect, Route } from "react-router-dom";

import "./index.scss";

import Content from "./Content";
import Sidebar from "./Sidebar";
import { getAuth } from "../../services/auth";

export default function DashboardLayout({ children, ...rest }) {
  const [showNavbar, setShowNavbar] = useState(false);
  return (
    <div id="wrapper">
      <Sidebar
        navbarChangeState={() => setShowNavbar(false)}
        navbarState={showNavbar}
      />
      <Route
        {...rest}
        render={(matchProps) => {
          if (getAuth()) {
            return (
              <Content navbarChangeState={() => setShowNavbar(true)}>
                {children}
              </Content>
            );
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/login",
                }}
              />
            );
          }
        }}
      />
      <div className={"overlay " + (showNavbar ? "active" : "")}></div>
    </div>
  );
}
