import * as types from '../actions/actionTypes';
const initialState = {
  loading: false,
  fiscalInformation: [],
  options: {},
};

export default function fiscalInformationReducer(state = initialState, action) {
  switch(action.type) {
    case types.FISCAL_INFORMATION_START:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_FISCAL_INFORMATION:
      return {
        ...state,
        fiscalInformation: action.payload
      };

    case types.FETCH_OPTIONS_FISCAL_INFORMATION:
      return {
        ...state,
        options: action.payload,
      }

    case types.FISCAL_INFORMATION_END:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}