import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { Card, Row, Col, Button, Form, Alert } from "react-bootstrap";

import logo from "../../assets/img/booksy-logo.png";
import logoB from "../../assets/img/booksy-logo-b.png";
import { useDispatch } from "react-redux";
import { selectBusiness } from "../../store/actions/businessAction";
import UnloggedLayout from "../../components/UnloggedLayout";

const initialForm = {
  email: "",
  password: "",
};

export default function Login() {
  const [formContent, setFormContent] = useState({ ...initialForm });
  const history = useHistory();
  const [errors, setErrors] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(selectBusiness(null));
  }, [dispatch]);

  function handleChange(e) {
    const { name, value } = e.target;
    setFormContent({
      ...formContent,
      [name]: value,
    });
  }
  const handleLogin = (e) => {
    e.preventDefault();
    setErrors("");

    const response = axios.post(
      "https://booksy-backend.herokuapp.com/auth/v1/user/sign_in",
      formContent
    );

    response
      .then((response) => {
        const authHeaders = {
          "access-token": response.headers["access-token"],
          client: response.headers["client"],
          uid: response.headers["uid"],
          expiry: response.headers["expiry"],
          "token-type": response.headers["token-type"],
        };
        window.localStorage.setItem("authHeaders", JSON.stringify(authHeaders));
        history.push("/");
      })
      .catch((response) => {
        setErrors("Usuário ou senha incorretos");
      });
  };

  return (
    <UnloggedLayout>
      <Card className="tiny">
        <Card.Body>
          <Form onSubmit={handleLogin}>
            <Row className="my-4">
              <img className="mx-auto" src={logo} alt="Logo da Booksy" />
            </Row>
            {errors && (
              <Row>
                <Col>
                  <Alert variant="danger">{errors}</Alert>
                </Col>
              </Row>
            )}
            <Row>
              <Col className="px-4">
                <Form.Group>
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control
                    type="email"
                    className="form-control"
                    name="email"
                    value={formContent.email}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="px-4">
                <Form.Group>
                  <Form.Label>Senha</Form.Label>
                  <Form.Control
                    type="password"
                    className="form-control"
                    name="password"
                    value={formContent.password}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="pt-4">
              <Col>
                <Button type="submit" className="button-full mx-auto w-full">
                  Fazer login com Booksy
                  <img src={logoB} alt="Booksy" className="booksy-button" />
                </Button>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <a href="/cadastrar" className="text-primary d-block text-center">
                  Não tem conta? Cadastre-se
                </a>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </UnloggedLayout>
  );
}
