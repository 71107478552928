export const setAuth = (auth) => {
  const authHeaders = {
    "access-token": auth["access-token"],
    client: auth["client"],
    uid: auth["uid"],
    expiry: auth["expiry"],
    "token-type": auth["token-type"],
  };
  localStorage.setItem("authHeaders", JSON.stringify(authHeaders));
}

export const getAuth = () => {
  return JSON.parse(localStorage.getItem("authHeaders"));
}

export const handleLogout = () => {
  localStorage.removeItem("authHeaders");
}