import axios from "axios";
import { setAuth, getAuth, handleLogout } from "./auth";

const instance = axios.create({
  baseURL: "https://booksy-backend.herokuapp.com/v1/",
});

instance.interceptors.request.use(
  (config) => {
    const authHeaders = getAuth();
    if (authHeaders) {
      config.headers[config.method] = {
        ...authHeaders,
        'Accept': '*/*',
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response.headers["access-token"]) {
      setAuth(response.headers);
    }

    return response;
  },
  (error) => {
    if (error.response.headers["access-token"]) {
      setAuth(error.response.headers);
    }

    if (error.response.status === 401) {
      if (error.config.url !== 'auth/login') {
        handleLogout();
        window.location.reload();
      }

    }
    return Promise.reject(error);
  }
);


export default instance;
