import api from "../../services/api";
import * as types from "./actionTypes";

export const invoicesStart = () => {
  return {
    type: types.INVOICES_START,
  };
};

export const invoicesEnd = () => {
  return {
    type: types.INVOICES_END,
  };
};

export const fetchInvoices = (invoices) => {
  return {
    type: types.FETCH_INVOICES,
    payload: invoices,
  };
};

export const fetchAllInvoices = () => {
  return (dispatch) => {
    dispatch(invoicesStart());
    return api.get("invoices").then((response) => {
      dispatch(fetchInvoices(response.data));
      dispatch(invoicesEnd());
    });
  };
};

export const fetchInvoice = (invoice) => {
  return {
    type: "FETCH_INVOICE",
    payload: invoice,
  };
};

export const fetchInvoiceById = (id) => {
  return (dispatch) => {
    dispatch(invoicesStart());
    return api.get("invoices").then((response) => {
      dispatch(fetchInvoice(response.data));
      dispatch(invoicesEnd());
    });
  };
};

export const handleSentInvoice = (formData) => {
  return (_, getState) => {
    const selected = getState().business.selected_business;
    if (selected) {
      return api.post(`businesses/${selected.id}/invoices`, {
        invoice: formData,
      });
    }
  };
};
