import React from "react";

import { Container, Row, Col, Card, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import DateRange from "../../components/DateRange";

export default function Home() {
  return (
    <Container>
      <Row className="align-items-center">
        <Col>
          <h6 className="mb-0">2020</h6>
          <h1 className="text-primary">Novembro</h1>
        </Col>
        <Col className="text-center text-lg-right mt-2 my-lg-auto">
          <DateRange />
        </Col>
      </Row>
      <Row>
        <Col sm={6} className="mt-4">
          <Card className="border-0 shadow-sm">
            <Card.Body>
              <h6>Notas de serviço emitidas</h6>
              <h2 className="text-right font-weight-bold">53</h2>
              <p className="text-right mb-0">R$ 950,00</p>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={6} className="mt-4">
          <Card className="border-0 shadow-sm">
            <Card.Body>
              <h6>Serviços realizados</h6>
              <h2 className="text-right font-weight-bold">60</h2>
              <p className="text-right mb-0">R$ 950,00</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg="12">
          <Card>
            <Card.Body className="py-2">
              <p className="text-dark raleway">Últimas notas emitidas</p>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Data</th>
                    <th>Descrição</th>
                    <th>Valor</th>
                    <th>Cliente</th>
                    <th>CPF</th>
                    <th>E-mail</th>
                    <th>Telefone</th>
                    <th>Opções</th>
                  </tr>
                </thead>
                <tbody className="text-nowrap">
                  <tr>
                    <td><span className="status status-green"></span></td>
                    <td>03/11/2020</td>
                    <td>Corte de cabelo masculino</td>
                    <td>R$ 35,00</td>
                    <td>José Carlos da Silva</td>
                    <td>123.456.789-00</td>
                    <td>jose.silva@gmail.com</td>
                    <td>(12) 34567-8912</td>
                    <td className="text-primary">
                      <FontAwesomeIcon icon={faFilePdf} /> xml
                    </td>
                  </tr>
                  <tr>
                    <td><span className="status status-gray"></span></td>
                    <td>03/11/2020</td>
                    <td>Corte de cabelo masculino</td>
                    <td>R$ 35,00</td>
                    <td>José Carlos da Silva</td>
                    <td>123.456.789-00</td>
                    <td>jose.silva@gmail.com</td>
                    <td>(12) 34567-8912</td>
                    <td className="text-primary">
                      <FontAwesomeIcon icon={faFilePdf} /> xml
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
