import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";


export default function Content(props) {
  return (
    <div id="content" className="bg-light p-2 p-lg-4">
      <div className="menu-button text-right">
        <button
          type="button"
          className="btn btn-sm btn-outline-primary"
          onClick={() => props.navbarChangeState()}
        >
          <FontAwesomeIcon icon={faBars} className="mr-2" />
          Menu
        </button>
      </div>
      { props.children }
    </div>
  );
}
