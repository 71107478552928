import api from "../../services/api";
import * as types from "./actionTypes";

export const businessStart = () => {
  return {
    type: types.BUSINESS_START,
  };
};

export const businessEnd = () => {
  return {
    type: types.BUSINESS_END,
  };
};

export const fetchBusiness = (information) => {
  return {
    type: types.FETCH_BUSINESS,
    payload: information,
  };
};

export const getAllUserBusiness = () => {
  return (dispatch, getState) => {
    dispatch(businessStart());
    return api.get("businesses").then((response) => {
      dispatch(fetchBusiness(response.data.businesses));
      if (!getState().business.selected_business) {
        dispatch(selectBusiness(response.data.businesses[0]));
      }
      dispatch(businessEnd());
    });
  };
};

export const selectBusinessAction = (business) => {
  return {
    type: types.SELECT_BUSINESS,
    payload: business,
  };
};

export const selectBusiness = (business) => {
  return (dispatch) => {
    dispatch(selectBusinessAction(business));
  };
};

export const changeBusiness = (information) => {
  return {
    type: types.FETCH_BUSINESS,
    payload: information,
  };
};

export const handleChangeBusiness = (formData) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return api
        .put(`businesses/${formData.get("business[id]")}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          dispatch(selectBusiness(data.business));
          resolve(data.business);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  };
};
