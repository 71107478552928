import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Switch } from "react-router-dom";

/* Layouts */
import DashboardLayout from "../components/DashboardLayout";

/* Views */
import Home from "../pages/home";
import Fiscal from "../pages/fiscal";
import General from "../pages/general";
import Login from "../pages/login";
import Services from "../pages/services";
import ServiceIssuance from "../pages/serviceIssuance";
import Invoices from "../pages/invoices";
import Signup from "../pages/signup";

export default function RouterPage() {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/cadastrar" component={Signup} />

        <Route>
          <DashboardLayout>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/cadastro-geral" component={General} />
              <Route path="/fiscal" component={Fiscal} />
              <Route path="/servicos/:id" component={ServiceIssuance} />
              <Route path="/servicos" component={Services} />
              <Route path="/notas" component={Invoices} />
            </Switch>
          </DashboardLayout>
        </Route>
      </Switch>
    </Router>
  );
}
