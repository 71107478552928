import axios from 'axios';
import * as types from './actionTypes';

export const locationStart = () => {
  return {
    type: types.LOCATION_START,
  };
};

export const locationEnd = () => {
  return {
    type: types.LOCATION_END,
  };
};

export const fetchStates = (states) => {
  return {
    type: types.FETCH_STATES,
    payload: states,
  };
};

export const fetchAllStates = () => {
  return (dispatch) => {
    dispatch(locationStart);
    return axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')
      .then((response) => {
        dispatch(fetchStates(response.data))
        dispatch(locationEnd);
      });
  };
};

export const fetchCities = (cities) => {
  return {
    type: types.FETCH_CITIES,
    payload: cities,
  };
};

export const fetchCitiesByState = (uf) => {
  return (dispatch) => {
    dispatch(locationStart);
    return axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`)
      .then((response) => {
        dispatch(fetchCities(response.data));
        dispatch(locationEnd);
      })
  }
}