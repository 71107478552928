import * as types from '../actions/actionTypes';
const initialState = {
  loading: false,
  businesses: [],
  selected_business: null,
};

export default function bussinessReducer(state = initialState, action) {
  switch(action.type) {
    case types.BUSINESS_START:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_BUSINESS:
      return {
        ...state,
        businesses: action.payload,
      };

    case types.BUSINESS_END:
      return {
        ...state,
        loading: false,
      };

     case types.SELECT_BUSINESS:
      return {
        ...state,
        selected_business: action.payload,
      }

    default:
      return state;
  }

}