import React from "react";

import "./index.scss";

export default function UnloggedLayout({ children }) {
  return (
    <div
      id="content"
      className="d-flex align-items-center justify-content-center"
    >
      {children}
    </div>
  );
}
